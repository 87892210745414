import { mapState } from 'vuex';
import { getActProduct } from '@/service/enroll-activity/index';
import RmbOrLimit from '@/components/common/rmbOrLimit/index.vue';
import { getBrandId } from '@/utils/localStorage';

export default {
    name: 'retail',

    components: {
        RmbOrLimit
    },

    data() {
        return {
            loading: true,
            finished: false,
            page: 1,
            list: [],
        }
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id
        }),
    },

    // 监听
    watch: {

    },

    mounted() {
        this.init()
    },

    methods: {
        init() {
            this.getList()
        },

        // 获取列表
        async getList() {
            let { list, page } = this;
            let signupId = this.$route.query.signupId;
            const res = await getActProduct({ mainId: signupId });
            this.loading = false;
            this.list = [...list, ...res]
            if (res.length < 10) this.finished = true
        },

        // 加载
        onLoad() {
            this.page++
            this.getList()
        },

        onJump(row) {
            const { signupId } = this.$route.query;
            this.$router.push({
                path: '/enroll-activity/retail-detail',
                query: {
                    id: row.id,
                    signupId: signupId,
                }
            })
        }
    }
}